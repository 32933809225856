import React, { useState,useEffect } from "react"
import { Container, Tab, Tabs } from "react-bootstrap"
import _ from "lodash"
import PropertyCard from "../PropertyCard/PropertyCard"
import CustomSlider from "../CustomSlider/CustomSlider"
import "./FeaturedProperties.scss"
import { getStrapiDataFromURL } from "../Common/site/utils"
import ScrollAnimation from "react-animate-on-scroll"
import FeatureSlider from "../CustomSlider/FeatureSlider"

const KEYS = {
  sale: "for-sale",
  rent: "to-rent",
  newHomes: "new-homes",
}

const FeaturedProperties = () => {
  const [key, setKey] = useState(KEYS.sale)

  const [saleLists, setSaleLists] = useState([])
  const [rentLists, setRentLists] = useState([])
  const [newHomesLists, setnewHomesLists] = useState([])



  const getSaleItems = async url => {
    const featuredPropertiesSale = await getStrapiDataFromURL(url);
    const filteredDataSale=featuredPropertiesSale.filter(data=>data.status!=="Sold")
    setSaleLists(filteredDataSale)
  }

  const getRentItems = async url => {
    const featuredPropertiesRent = await getStrapiDataFromURL(url)
    const filteredDataRent=featuredPropertiesRent.filter(data=>data.status!=="Sold")
    setRentLists(filteredDataRent)
  }

  const getNewHomesItems = async url => {
    const featuredPropertiesNewHomes = await getStrapiDataFromURL(url)
    const filteredDataNewHomes=featuredPropertiesNewHomes.filter(data=>data.status!=="Sold")
    setnewHomesLists(filteredDataNewHomes)
  }


  useEffect(() => {
      let saleUrl =
        `/api/stb-lists/item/featured_sales?publish=true`
      getSaleItems(saleUrl)
      let rentUrl=
      `/api/stb-lists/item/featured_rent?publish=true`
      getRentItems(rentUrl)
      
      let newHomesUrl=
      `/api/stb-lists/item/featured_homes?publish=true`
      getNewHomesItems(newHomesUrl)
    
  }, [])


  useEffect(() => {
    setTimeout(() => {
      let getClass = document.querySelector(".featured-properties-container")
      if (!getClass) return

      let getId = document.getElementById("footer-component")
      const { offsetLeft } = getId

      let padding;
      
        padding = `${offsetLeft}px`

        getClass.style.paddingLeft = padding
      // slickDots.style.position = "relative"

    }, 1000)
  }, [])


  const SliderTab = ({ items }) => {
    if (_.isEmpty(items)) return null


    return (
      <FeatureSlider className="featured-slider-wrap">
        {items?.map((property, i) => {
          const parseImage=property?.images&&JSON.parse(property.images)
          const imageUrl=parseImage?.length>0&&parseImage[0]
          const pricemonthly = property?.extra&&JSON.parse(property.extra)
          const address = property?.address&&JSON.parse(property.address)
          return(
            <ScrollAnimation duration={.5} animateIn="animate__slideInUp" animateOnce delay={(i+1) * 100} offset={50}>
          <PropertyCard
            address={address.display_address}
            description={property.title}
            image={imageUrl}
            price={property.price}
            slug={property.slug}
            id={property.crm_id}
            strapi_id={property.id}
            search_type={property.search_type}
            status={property.status}
            price_qualifier={property.price_qualifier}
            price_monthly={pricemonthly?.price_monthly}
            imagetransforms={property.imagetransforms}
            department={property.department}
            componentType="featured-properties"
            floorarea_min={property.floorarea_min}
            floorarea_type={property.floorarea_type}
            bedroom={property.bedroom}
          />
          </ScrollAnimation>
          )
  })}
      </FeatureSlider>
    )
  }


  return (
    <section className="featured-properties-section">
      <div className="featured-properties-container">
      {/* <ScrollAnimation duration={.5} animateIn="animate__slideInUp" animateOnce delay={100} offset={10}> */}
        <h2 className="title">Featured Listings</h2>
        
        <Tabs
          activeKey={key}
          onSelect={k => setKey(k)}
          className="featured-properties-tab-section"
        >
          {saleLists?.length > 0 &&
          <Tab eventKey={KEYS.sale} title="Sales">
            <SliderTab items={saleLists} />
          </Tab>
          }
          {rentLists?.length > 0 &&
          <Tab eventKey={KEYS.rent} title="Lettings">
            <SliderTab items={rentLists} />
          </Tab>
          }
          {newHomesLists?.length > 0 &&
          <Tab eventKey={KEYS.newHomes} title="New Homes">
            <SliderTab items={newHomesLists} />
          </Tab>
          }
        </Tabs>
        {/* </ScrollAnimation> */}
      </div>
    </section>
  )
}
export default FeaturedProperties
