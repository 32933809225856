import React, { useState, useEffect } from "react"
import "./ReviewsSlider.scss"
import { getStrapiDataFromURL } from "../Common/site/utils"
import CustomSliderNew from "../CustomSlider/CustomSliderNew"
import { Container } from "react-bootstrap"
import GetReview from "../GetReview/GetReview"
import ScrollAnimation from "react-animate-on-scroll"

const ReviewsSlider = () => {
  const [loading, setloading] = useState(false)
  const [reviewData, setReviewData] = useState([])

  const getitems = async url => {
    const googleReviews = await getStrapiDataFromURL(url);
    const getFiveStar=googleReviews.filter(review=>review.starRating==="FIVE");
    setReviewData(getFiveStar?.slice(0, 6))
    setloading(false)
  }
  useEffect(() => {
    setloading(true)
    let url = `/api/stb-google-reviews/google-review-reviews`
    getitems(url)
  }, [])

  const capitalize = s => {
    if (typeof s !== "string") return ""
    return s?.charAt(0).toUpperCase() + s?.slice(1).toLowerCase()
  }


  return (
    <div className="review-slider-component">
      <Container className="review-container">
      {reviewData?.length > 0 &&
        <CustomSliderNew className="reviews-slider-wrap" googlereview>
         {
            reviewData?.map((review, i) => {
              return (
                <ScrollAnimation animateIn="animate__slideInUp" animateOnce delay={(i+1) * 100} offset={50}>
                  <div className="review-list">
                    <GetReview starRating={review.starRating} />
                    <p className="comments">{review?.comment}</p>
                    <p className="name">{capitalize(review?.reviewer?.displayName)}</p>
                  </div>
                </ScrollAnimation>
              )
            })
            }
        </CustomSliderNew>
            }
      </Container>
    </div>
  )
}

export default ReviewsSlider
