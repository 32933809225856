import React from "react"
import ReviewStar from "../../images/rating-icon.svg"
import "./GetReview.scss"

const GetReview = ({ starRating }) => {
  return (
    <>
      <ul className="ratings">
        {starRating == "ONE" && (
          <li>
            <img src={ReviewStar} alt="review1" />
          </li>
        )}
        {starRating == "TWO" && (
          <>
            <li>
              <img src={ReviewStar} alt="review1" />
            </li>
            <li>
              <img src={ReviewStar} alt="review2" />
            </li>
          </>
        )}
        {starRating == "THREE" && (
          <>
            <li>
              <img src={ReviewStar} alt="review1" />
            </li>
            <li>
              <img src={ReviewStar} alt="review2" />
            </li>
            <li>
              <img src={ReviewStar} alt="review3" />
            </li>
          </>
        )}

        {starRating == "FOUR" && (
          <>
            <li>
              <img src={ReviewStar} alt="review1" />
            </li>
            <li>
              <img src={ReviewStar} alt="review2" />
            </li>
            <li>
              <img src={ReviewStar} alt="review3" />
            </li>
            <li>
              <img src={ReviewStar} alt="review4" />
            </li>
          </>
        )}
        {starRating == "FIVE" && (
          <>
            <li>
              <img src={ReviewStar} alt="review1" />
            </li>
            <li>
              <img src={ReviewStar} alt="review2" />
            </li>
            <li>
              <img src={ReviewStar} alt="review3" />
            </li>
            <li>
              <img src={ReviewStar} alt="review4" />
            </li>
            <li>
              <img src={ReviewStar} alt="review5" />
            </li>
          </>
        )}
      </ul>
    </>
  )
}

export default GetReview
