import _ from "lodash"
import React from "react"
import Slider from "react-slick"
import "./CustomSlider.scss"

const SIZES = {
  sm: {
    mobile: 1,
    tablet: 2,
    laptop: 3,
    desktop: 3,
  },
  md: {
    mobile: 1,
    tablet: 2,
    laptop: 3,
    desktop: 4,
  },
  lg: {
    mobile: 2,
    tablet: 4,
    laptop: 4,
    desktop: 5,
  },
  xl: {
    mobile: 2,
    tablet: 4,
    laptop: 5,
    desktop: 6,
  },
}

export const CustomSlider = ({ children, className, size, darkMode }) => {
  const variant = _.isEmpty(size) ? SIZES.sm : SIZES[size]

  const options = {
    dots: false,
    arrows: false,
    infinite: false,
    speed: 800,
    slidesToShow: 4,
    slidesToScroll: 1,
    centerMode: false,
    responsive: [
      {
        breakpoint: 3000,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: false,
          dots: true,
        },
      },
      {
        breakpoint: 1599,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: false,
          dots: true,
        },
      },
      {
        breakpoint: 1399,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: false,
          dots: true,
        },
      },
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 2.2,
          slidesToScroll: 1,
          dots: true,
        },
      },
      {
        breakpoint: 1023,
        settings: {
          slidesToShow: 1.5,
          slidesToScroll: 1,
          dots: true,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1.1,
          slidesToScroll: 1,
          dots: true,
        },
      },
    ],
  }

  return (
    <Slider className={`custom-slider ${className}`} {...options}>
      {children}
    </Slider>
  )
}

export default CustomSlider
