import React from "react"
import { Container } from "react-bootstrap"
import parse from "html-react-parser"
import "./AboutComponent.scss"
import ContentModule from "../../modules/content-render";
import ScrollAnimation from "react-animate-on-scroll";
const AboutComponent = ({ block, about_title }) => {
  const heading = about_title?.data?.about_title
  return (
    <div className="about-component-wrapper">
      <Container>
        <div className="about-section-content">
        <ScrollAnimation animateIn="animate__slideInUp" animateOnce delay={100} offset={10}>
          <ContentModule Content={heading} />
          </ScrollAnimation>
          <div className="about-items">
            {block &&
              block.map((data, i) => {
                return(
                  <ScrollAnimation animateIn="animate__slideInUp" animateOnce delay={(i+1) * 100} offset={25}>
                  <div className="list-items">
                    <div className="list-sections">
                      <img src={data.image.url} alt={data.title} />
                      <div className="list-description">
                      <p className="bold-text">{data.title}</p>
                        <p className="list-content">
                          {parse(data.content.data.content)}
                        </p>
                      </div>
                    </div>
                  </div>
                  </ScrollAnimation>
                )
              }
                
              )}
          </div>
        </div>
      </Container>
    </div>
  )
}

export default AboutComponent
