import React, { useEffect, useState } from "react"
import { Link, graphql } from "gatsby"
import loadable from "@loadable/component"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Banner from "../components/Banner/Banner"
import Banner_Cta from "../components/Banner_Cta/Banner_Cta"
import TileBlock from "../components/TileBlock/TileBlock"
import FeaturedProperties from "../components/FeaturedProperties/FeaturedProperties"
import AboutComponent from "../components/AboutComponent/AboutComponent"
import Instagram from "../components/Instagram/Instagram"
import ReviewsSlider from "../components/ReviewsSlider/ReviewsSlider"
import ImageModule from "../modules/image-render"
import { homeValuation, instantValuation } from "../site/urls"
import HomeStructuredData from "../components/Seo/HomeStructuredData"
const HomePageTemplate = ({ data }, props) => {
  const PageData = data?.strapiPage
  const BannerData = data?.strapiBannerGallery
  const logo = PageData.banner[0].logo
  const { title, imagetransforms, strapi_id } = PageData

  var imagenamelogo = "page.banner_section_logo_image.landing_logo_image";

  let processedImages = JSON.stringify({});
  if (imagetransforms) {
    processedImages = imagetransforms.banner_section_banner_image_Transforms;
  }


  const [renderComponent, setRenderComponent] = useState(false);
  useEffect(() => {
    window.addEventListener("mousemove", () => {
      if (renderComponent === false) {
        setRenderComponent(true)
      }
    })
    window.addEventListener("touchmove", () => {
      if (renderComponent === false) {
        setRenderComponent(true)
      }
    })
    window.addEventListener("keypress", () => {
      if (renderComponent === false) {
        setRenderComponent(true)
      }
    })

  }, [])


  return (
    <Layout
      popularSearch={PageData?.select_popular_search?.title}
      PageData={PageData}
    >
      <div className="bottom-right-image-home">
        <div className="hover-block">
          <div className="block-elements">
            {/* <Link to={instantValuation}>Instant Online Valuation</Link> */}
            {/* <div className="vertical-line"></div> */}
            <Link to={homeValuation}>Home Visit Valuation</Link>
          </div>
        </div>
        <div className="banner-img"></div>
        {/* <ImageModule ImageSrc={logo} 
         altText={`${logo?.alternativeText ? logo?.alternativeText : title ? title : "Home"} banner`}
          imagetransforms={processedImages} 
          renderer="bgImg" 
          imagename={imagenamelogo} strapi_id={strapi_id} classNames="img-fluid banner-img" /> */}
      </div>
      <HomeStructuredData />
      <SEO
        title={
          PageData?.seo?.metaTitle ? PageData?.seo?.metaTitle : PageData?.title
        }
        description={
          PageData?.seo?.metaDescription
            ? PageData?.seo?.metaDescription
            : PageData?.title
        }
      />
      <Banner
        tag="landing"
        title={PageData.title}
        banner={PageData.banner}
        strapi_id={PageData.strapi_id}
        imagetransforms={PageData.imagetransforms}
        BannerData={BannerData}
      />

      {PageData.add_page_modules?.map((item, i) => {
        return (
          <div>
            {item?.__typename ===
              "STRAPI__COMPONENT_PAGE_MODULES_CTA_BANNER" && (
                <Banner_Cta {...item} />
              )}
            {item?.__typename ===
              "STRAPI__COMPONENT_PAGE_MODULES_IMAGE_TAILS" && (
                <TileBlock
                  imagetransforms={PageData.imagetransforms}
                  strapi_id={PageData?.strapi_id}
                  {...item}
                />
              )}
            {item?.__typename ===
              "STRAPI__COMPONENT_PAGE_MODULES_GLOBAL_MODULE" &&
              item?.select_module === "featured_properties" && renderComponent && (
                <FeaturedProperties {...item} />
              )}
            {item?.__typename ===
              "STRAPI__COMPONENT_PAGE_MODULES_ABOUT_SECTION" && (
                <AboutComponent {...item} />
              )}
            {item?.__typename ===
              "STRAPI__COMPONENT_PAGE_MODULES_GLOBAL_MODULE" &&
              item?.select_module === "instagram" && renderComponent && <Instagram {...item} />}
            {item?.__typename ===
              "STRAPI__COMPONENT_PAGE_MODULES_GLOBAL_MODULE" &&
              item?.select_module === "google_reviews_slider" && renderComponent && (
                <ReviewsSlider {...item} />
              )}
          </div>
        )
      })}
    </Layout>
  )
}

export default HomePageTemplate

export const query = graphql`
  query ($page_id: String) {
    strapiBannerGallery {
          title
          cta_link {
            label
            link_type
            link {
              slug
            }
          }
          limit
          banner_item {
            address
            image {
              url
            }
          }
          strapi_id
          imagetransforms {
            banner_section_banner_image_Transforms
          }
    }
    strapiPage(id: { eq: $page_id }) {
      ...PageFragment
      imagetransforms {
        banner_section_banner_image_Transforms
        banner_section_logo_image_Transforms
        image_tails_tail_image_Transforms
      }

      add_page_modules {
        ... on STRAPI__COMPONENT_PAGE_MODULES_ABOUT_SECTION {
          __typename
          ...ABOUT_SECTIONFragment
        }
        ... on STRAPI__COMPONENT_PAGE_MODULES_CTA_BANNER {
          __typename
          ...CTA_BANNERFragment
        }
        ... on STRAPI__COMPONENT_PAGE_MODULES_GLOBAL_MODULE {
          __typename
          select_module
          title
        }
        ... on STRAPI__COMPONENT_PAGE_MODULES_IMAGE_TAILS {
          __typename
          ...IMAGE_TAILSFragment
        }
      }
    }
  }
`
