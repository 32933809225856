import React from "react"
import { Helmet } from "react-helmet"

function HomeStructuredData() {
  var ldJson = {
    "@context": "https://schema.org",
    "@type": "Organization",
    name: "Olivers Town",
    alternateName: "Olivers Town estate agents and letting agents",
    url: "https://oliverstown.q.starberry.com/",
    logo: "https://ggfx-oliverstown.s3.eu-west-2.amazonaws.com/i.prod/logo_a1747dfb98.png",
    sameAs: [
      "https://www.instagram.com/oliverstownestateagents/",
      "https://www.facebook.com/oliverstownestateagents",
      "https://twitter.com/oliverstown1",
      "https://www.linkedin.com/company/olivers-town/",
      "https://www.pinterest.com/81ee3d170e2792c52d6c4e4888f827/",
    ],
  }
  return (
    <Helmet>
      <script type="application/ld+json">
        {JSON.stringify(ldJson, null, 2)}
      </script>
    </Helmet>
  )
}

export default HomeStructuredData
